import React from "react";
import "./Promo.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckDouble } from "@fortawesome/free-solid-svg-icons";

const Promo = ({ data, promo, promoclick }) => {
  return (
    <div className="promocontainer" id="Promo">
      <div className="promoimage">
        <img src={promo} className="imgpromo" alt="Promo" />
      </div>
      <div className="promoitem">
        <div className="promojudul">Promo</div>
        <div className="promo">
          {data.items.map((item, index) => (
            <div className="item" key={index}>
              <FontAwesomeIcon icon={faCheckDouble} />
              &nbsp;&nbsp;{item}
            </div>
          ))}
        </div>
        <div className="promobutton">
          <button onClick={promoclick} className="promowa">
            Whatsapp
          </button>
        </div>
      </div>
    </div>
  );
};

export default Promo;
