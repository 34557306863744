import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import "./Navbar.scss";
import logo from "../../media/logoputih.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCaretDown} from "@fortawesome/free-solid-svg-icons";
import {HashLink} from "react-router-hash-link";

const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isProductsOpen, setIsProductsOpen] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const dropdownRef = useRef(null);
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const toggleProductsDropdown = () => {
    setIsProductsOpen(!isProductsOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsProductsOpen(false);
    }
  };
  useEffect(() => {
    if (isProductsOpen) {
      setTimeout(() => {
        document.addEventListener("click", handleClickOutside);
      }, 0);
    } else {
      document.removeEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isProductsOpen]);

  const changebackground = () => {
    if (window.scrollY >= 80) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  const handleDropdownItemClick = () => {
    setIsProductsOpen(false);
  };
  window.addEventListener("scroll", changebackground);

  const navbarwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <nav className={navbar ? "navbarcontainer active" : "navbarcontainer"}>
      <div className="navbar-logo">
        <Link to="/">
          <img className="navbarlogo" src={logo} alt="Navapark" />
        </Link>
      </div>
      <div className="navbar-link">
        <ul className={`navbar-item ${isMobileMenuOpen ? "active" : ""}`}>
          <li className="divider">
            <HashLink to="/#About">About</HashLink>
          </li>
          <li>
            <HashLink to="/#Promo">Promo</HashLink>
          </li>
          <li className="dropdown">
            <button className="buttonproduk" onClick={toggleProductsDropdown}>
              Products <FontAwesomeIcon icon={faCaretDown} />
            </button>
            {isProductsOpen && (
              <ul className="dropdown-content" ref={dropdownRef}>
                <li>
                  <Link onClick={handleDropdownItemClick} to="Laurel">
                    Laurel
                  </Link>
                </li>
                <li>
                  <Link onClick={handleDropdownItemClick} to="Layton">
                    Layton
                  </Link>
                </li>
                <li>
                  <Link onClick={handleDropdownItemClick} to="Lyndon">
                    Lyndon
                  </Link>
                </li>
                <li>
                  <HashLink
                    onClick={handleDropdownItemClick}
                    smooth
                    to="/#Marigold">
                    Marigold
                  </HashLink>
                </li>
                <li>
                  <HashLink
                    onClick={handleDropdownItemClick}
                    smooth
                    to="/#Northpoint">
                    Northpoint
                  </HashLink>
                </li>
                {/* Add more dropdown items here */}
              </ul>
            )}
          </li>
          <li className="divider2">
            <HashLink to="/#Facilities">Facilities</HashLink>
          </li>
        </ul>
      </div>
      <div className="navbar-button">
        <button onClick={navbarwa} className="wabutton">
          Whatsapp
        </button>
      </div>
      <div className="navbar-burger">
        <button
          className={`burger-button ${isMobileMenuOpen ? "active" : ""}`}
          onClick={toggleMobileMenu}>
          <span className="bar"></span>
          <span className="bar"></span>
          <span className="bar"></span>
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
