import React from "react";
import "./Surrounding.scss";
import aeon from "../../media/Surrounding Area/56.webp";
import prasmul from "../../media/Surrounding Area/57.webp";
import binus from "../../media/Surrounding Area/58.webp";
import breeze from "../../media/Surrounding Area/59.webp";
import qbig from "../../media/Surrounding Area/60.webp";
import eka from "../../media/Surrounding Area/61.webp";
import Slider from "react-slick";

const Surrounding = ({ bg, color }) => {
  const slides = [
    { src: aeon, des: "AEON Mall" },
    { src: prasmul, des: "Prasetya Mulya University" },
    { src: binus, des: "Binus University" },
    { src: breeze, des: "The Breeze" },
    { src: qbig, des: "QBIG Mall" },
    { src: eka, des: "Eka Hospital" },
  ];
  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <div className="surroundingcontainer" style={bg}>
      <div className="title" style={color}>
        Surrounding Area
      </div>
      <div className="content">
        {slides.map((slide, index) => (
          <div className="center" key={index}>
            <img className="imgcontent" src={slide.src} alt="Surrounding" />
            <div className="imgdes">
              <span>{slide.des}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="content1">
        <Slider {...settings}>
          {slides.map((slide, index) => (
            <div className="center" key={index}>
              <img className="imgcontent" src={slide.src} alt="Surrounding" />
              <div className="imgdes">
                <span>{slide.des}</span>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Surrounding;
