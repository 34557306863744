import lt12_1 from "./T12 (1).webp";
import lt12_2 from "./T12 (2).webp";
import lt12_3 from "./T12 (3).webp";
import lt12_4 from "./T12 (4).webp";
import lt12_5 from "./T12 (5).webp";
import lt12_6 from "./T12 (6).webp";
import lt12_7 from "./T12 (7).webp";

const lt12 = [lt12_1, lt12_2, lt12_3, lt12_4, lt12_5, lt12_6, lt12_7];

export default lt12;
