import northpoint1 from "./North Point (1).webp";
import northpoint2 from "./North Point (2).webp";
import northpoint3 from "./North Point (3).webp";
import northpoint4 from "./North Point (4).webp";
import northpoint5 from "./North Point (5).webp";
import northpoint6 from "./North Point (6).webp";
import northpoint7 from "./North Point (7).webp";
import northpoint8 from "./North Point (8).webp";
import northpoint9 from "./North Point (9).webp";
import northpoint10 from "./North Point (10).webp";

const northpoint = [
  northpoint1,
  northpoint2,
  northpoint3,
  northpoint4,
  northpoint5,
  northpoint6,
  northpoint7,
  northpoint8,
  northpoint9,
  northpoint10,
];

export default northpoint;
