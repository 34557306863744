import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import Navbar from "./component/navbar/Navbar";
import Footer from "./component/Footer/Footer";
import Layton from "./pages/Layton/Laytone";
import Laurel from "./pages/Laurel/Laurel";
import Lyndon from "./pages/Lyndon/Lyndon";
import Privacy from "./component/privacy/Privacy"
import ScrollToTop from "./component/ScrollToTop";
import { FloatingWhatsApp } from "react-floating-whatsapp";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="Layton" element={<Layton />} />
          <Route path="Laurel" element={<Laurel />} />
          <Route path="Lyndon" element={<Lyndon />} />
          <Route path="/privacy-policy" element={<Privacy />} />
        </Routes>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0"
        >
          <FloatingWhatsApp />
        </a>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
