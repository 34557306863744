import React from "react";
import "./Cardhome.scss";
import laytonhome from "../../../media/Layton.webp";
import laurelhome from "../../../media/Laurel.webp";
import lyndonhome from "../../../media/Lyndon.webp";
import {Link} from "react-router-dom";

const Cardhome = () => {
  return (
    <div className="cardhomecontainers">
      <div className="cardtitle">Residential's</div>
      <div className="cardsection">
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={laurelhome} alt="Laurel" />
          </div>
          <div className="carddes">
            <div className="title">Laurel</div>
            <div className="subtitle">Siap Huni, Free Lift</div>
            <div className="subtitle">Cicilan Start 70 Juta/Bulan</div>
            <hr />
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Laurel)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
              <Link to="Laurel">
                <button className="wacard">Details</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={laytonhome} alt="Lyndon" />
          </div>
          <div className="carddes">
            <div className="title">Layton</div>
            <div className="subtitle">Discount 10%, Free Furnished</div>
            <div className="subtitle">Cicilan Start 70 Juta/Bulan</div>
            <hr />
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Layton)%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
              <Link to="Laytone">
                <button className="wacard">Details</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="cardhome">
          <div className="cardpicture">
            <img className="cardp" src={lyndonhome} alt="Layton" />
          </div>
          <div className="carddes">
            <div className="title">Lyndon</div>
            <div className="subtitle">Siap Huni, Free Lift</div>
            <div className="subtitle">Cicilan Start 70 Juta/Bulan</div>
            <hr />
            <div className="buttoncard">
              <button
                onClick={() =>
                  window.open(
                    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20rumah%20(Lyndon)%20https://marketingbsdcity-navapark.com/&type=phone_number&app_absent=0",
                    "_blank"
                  )
                }
                className="wacard">
                Whatsapp
              </button>
              <Link to="Lyndon">
                <button className="wacard">Details</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cardhome;
