import React from "react";
import Cardproduct from "../../../component/cardproduct/Cardproduct";
import lyndont12 from "../../../media/Product/Lyndon/T12/lyndont12";
import lyndont15 from "../../../media/Product/Lyndon/T15/lyndont15";
import lyndont19 from "../../../media/Product/Lyndon/T19/lyndont19";
import data from "./data.json";

const imageMap = {
  lyndont12,
  lyndont15,
  lyndont19,
};

const Cardlyndon = () => {
  return (
    <div>
      <div className="laytoncontainer">
        {data.cards.map((card, index) => {
          return (
            <Cardproduct
              key={index}
              src={imageMap[card.imagesKey]}
              title={card.title}
              sub={card.sub}
              style={card.style}
              details={card.details}
              price={card.price}
              whatsappLink={card.whatsappLink}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Cardlyndon;
