import React from "react";
import "./Secondhero.scss";
import contentlogo from "../../../media/Navapark.png";
import kiriatas from "../../../media/kiri atas.png";
import kiribawah from "../../../media/kiri bawah.png";
import kananatas from "../../../media/kanan atas.png";
import kananbawah from "../../../media/kanan bawah.png";

const SecondHero = () => {
  return (
    <div className="secondherocontainer" id="About">
      <div className="secondmiddle">
        <div className="leftcontent">
          <img src={contentlogo} className="leftlogo" alt="Nava" />
          <div className="contentjudul">Home is a reflection of elegance</div>
          <div className="contentdescription">
            Navapark adalah sebuah kompleks perumahan mewah yang dikembangkan
            oleh Sinarmasland Tbk terletak di BSD City, Indonesia. Navapark
            sendiri memiliki beberapa cluster seperti Layton, Lyndon, Laurel. Di
            dalam kawasan Navapark juga terdapat sebuah apartemen bernama
            Marigold. Terdapat juga area komersil seperti Northpoint
          </div>
        </div>
        <div className="rightcontent">
          <div className="leftpicture">
            <div className="kiriatas">
              <img src={kiriatas} className="satu" alt="Nava" />
            </div>
            <div className="kiribawah">
              <img src={kiribawah} className="dua" alt="Nava" />
            </div>
          </div>
          <div className="rightpicture">
            <div className="kananatas">
              <img src={kananatas} className="tiga" alt="Nava" />
            </div>
            <div className="kananbawah">
              <img src={kananbawah} className="empat" alt="Nava" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondHero;
