import React from "react";
import "./Productlogo.scss";

const Productlogo = ({ prodlogo }) => {
  return (
    <div className="productlogocon">
      <img className="productlogos" src={prodlogo} alt="Product" />
    </div>
  );
};

export default Productlogo;
