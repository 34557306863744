import React from "react";
import Cardproduct from "../../../component/cardproduct/Cardproduct";
import t12 from "../../../media/Product/Layton/T12/t15";
import t15 from "../../../media/Product/Layton/T15/t12";
import t15roof from "../../../media/Product/Layton/T15 Rooftop/t15roof";
import data from "./data.json";

const imageMap = {
  t12,
  t15,
  t15roof,
};

const Cardlayton = () => {
  return (
    <div className="laytoncontainer">
      {data.cards.map((card, index) => {
        return (
          <Cardproduct
            key={index}
            src={imageMap[card.imagesKey]}
            title={card.title}
            sub={card.sub}
            style={card.style}
            details={card.details}
            price={card.price}
            whatsappLink={card.whatsappLink}
          />
        );
      })}
    </div>
  );
};

export default Cardlayton;
