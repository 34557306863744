import facilities1 from "./1.webp";
import facilities2 from "./2.webp";
import facilities3 from "./3.webp";
import facilities4 from "./4.webp";
import facilities5 from "./5.webp";
import facilities6 from "./6.webp";
import facilities7 from "./7.webp";
import facilities8 from "./8.webp";
import facilities9 from "./9.webp";
import facilities10 from "./10.webp";

const facilitiesimage = [
  facilities1,
  facilities2,
  facilities3,
  facilities4,
  facilities5,
  facilities6,
  facilities7,
  facilities8,
  facilities9,
  facilities10,
];

export default facilitiesimage;
