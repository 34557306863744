import React, {useEffect, useState} from "react";
import "./Sectionruko.scss";
import Cardruko from "../../../component/cardruko/Cardruko";
import Marigold from "../../../media/Marigold/marigold";
import northpoint from "../../../media/Northpoint/Northpoint";

const Sectionruko = () => {
  const mediaMatch = window.matchMedia("(min-width: 768px)");
  const [matches, setMatches] = useState(mediaMatch.matches);

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    mediaMatch.addListener(handler);
    return () => mediaMatch.removeListener(handler);
  });

  const ruko1 = {
    title: "Marigold Navapark Apartement in Navapark",
    subtitle: "Botanical Park At Your Doorstep",
    details: {
      luas: "255",
      rumah: "156",
      kamar: "3+1",
      mandi: "3+1",
    },
  };
  const ruko2 = {
    title: "Northpoint Commercial Area in Navapark",
    subtitle: "Botanical Park At Your Doorstep",
    details: {
      luas: "255",
      rumah: "156",
      kamar: "3+1",
      mandi: "3+1",
    },
  };
  const styles = {
    container: (isRowBased) => ({
      flexDirection: isRowBased ? "row" : "column",
    }),
  };
  const styles2 = {
    container: (isRowBased) => ({
      flexDirection: isRowBased ? "row-reverse" : "column",
    }),
  };
  const wam = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ruko%20Marigold%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const wan = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20ruko%20Northpoint%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="sectionruko" id="Marigold">
      <Cardruko
        title={ruko1.title}
        subtitle={ruko1.subtitle}
        details={ruko1.details}
        slider={Marigold}
        style={styles.container(matches)}
        whatsappbutton={wam}
      />
      <div id="Northpoint"></div>
      <Cardruko
        title={ruko2.title}
        subtitle={ruko2.subtitle}
        details={ruko2.details}
        slider={northpoint}
        style={styles2.container(matches)}
        whatsappbutton={wan}
      />
    </div>
  );
};

export default Sectionruko;
