import React from "react";
import "./Facilities.scss";
import facilitiesimage from "../../media/Fasilitas/facilities";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Facilities = () => {
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    lazyLoad: true,
    speed: 500,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="surounding" id="Facilities">
      <div className="surrounding-title">Facilities</div>

      <Slider {...settings}>
        {facilitiesimage.map((slide, index) => (
          <div className="slider-container " key={index}>
            <img src={slide} alt="facilities" className="image" />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Facilities;
