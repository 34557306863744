import React from "react";
import "./Producthero.scss";

const Producthero = ({ logoproduct, header, des }) => {
  return (
    <div className="productcontainer">
      <div className="productlogo">
        <img className="logoproduct" src={logoproduct} alt="product" />
      </div>
      <div className="productheader">{header}</div>
      <div className="productdes">{des}</div>
    </div>
  );
};

export default Producthero;
