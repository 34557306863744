import React from "react";
import "./Cardlaurel.scss";
import lt12 from "../../../media/Product/Laurel/T12/laurelt12";
import lt10 from "../../../media/Product/Laurel/T10/laurelt10";
import data from "./data.json";
import Cardproduct from "../../../component/cardproduct/Cardproduct";

const Cardlaurel = () => {
  const imageMap = {
    lt12,
    lt10,
  };

  return (
    <div className="laurelcontainer">
      {data.cards.map((card, index) => {
        return (
          <Cardproduct
            key={index}
            src={imageMap[card.imagesKey]}
            title={card.title}
            sub={card.sub}
            style={card.style}
            details={card.details}
            price={card.price}
            whatsappLink={card.whatsappLink}
          />
        );
      })}
    </div>
  );
};

export default Cardlaurel;
