import t15_1 from "./T15 (1).webp";
import t15_2 from "./T15 (2).webp";
import t15_3 from "./T15 (3).webp";
import t15_4 from "./T15 (4).webp";
import t15_5 from "./T15 (5).webp";
import t15_6 from "./T15 (6).webp";
import t15_7 from "./T15 (7).webp";
import t15_8 from "./T15 (8).webp";
import t15_9 from "./T15 (9).webp";

const t15 = [t15_1, t15_2, t15_3, t15_4, t15_5, t15_6, t15_7, t15_8, t15_9];

export default t15;
