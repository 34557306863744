import React from "react";
import "./Footer.scss";
import {Link} from "react-router-dom";
import logo from "../../media/logoputih.png";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer-content">
          <div className="footer-logo">
            <img alt="footerlogo" src={logo} className="logo" />
          </div>
          <div className="footer-description">
            <div className="footer-title">Marketing Gallery</div>
            <div className="footer-subtitle">Navapark BSD City</div>

            <div className="footer-alamat">
              Lengkong Kulon, Pagedangan, Tangerang Regency, Banten 15331
              <br />
              Contact Us : +6282122005507
            </div>
            <Link to="/privacy-policy">
              <div className="footer-contact">Privacy Policy</div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
