import React from "react";
import headerlayton from "../../media/Header Nava Web/Header Desktop/Header Layton.webp";
import Hero from "../../component/hero/Hero";
import Producthero from "../../component/producthero/Producthero";
import laytonlogo from "../../media/Logo/Layton.png";
import Promo from "../../component/promo/Promo";
import laytonpromoimg from "../../media/Banner Promo/Layton.webp";
import Productlogo from "../../component/productlogo/Productlogo";
import Cardlayton from "../../section/Layton/cardlayton/Cardlayton";
import Surrounding from "../../component/surrounding/Surrounding";
import Map from "../../component/map/Map";
import laytonemobile from "../../media/Header Nava Web/Header Mobile/Layton.webp";

const productdata = {
  src: laytonlogo,
  header: "Home is a reflection of elegance",
  des: "Layton Navapark adalah cluster terbaru di Navapark BSD City, yang dikembangkan oleh Sinarmas Land bekerjasama dengan Hongkong Land. Cluster ini terletak di lokasi sangat premium, dekat dengan gerbang utara Navapark dan Country Club Navapark. Layton Navapark menawarkan rumah mewah 3 lantai serta beberapa tipe yang memiliki rooftop area. Fasilitas yang ditawarkan termasuk botanical garden seluas 10 hektar, promenade seluas 10.000 m2, jogging track, backyard jogging track, dan multi-purpose lawn, Country Club. Secra lokasi layton memiliki lokasi yang strategis dimana hanya 5 menit saja ke AEON BSD, 7 Menit ke QBig, 10 Menit ke Pintu Tol Serpong-Bintaro, 2 Menit ke The Breeze.",
};

const promolayton = {
  items: [
    "Discount 10% ",
    "Free Furnished",
    "Sanitary by Kohler",
    "Free AC",
    "Free CCTV",
    "Free Canopy",
    "Free Solar Panel",
    "Free Water Heater",
    "Free Smarthome System",
  ],
};
const bg = {
  backgroundColor: "white",
};
const color = {
  color: "#EBBB4A",
};
const walayton = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Promo%20terbaru%20produk%20(Layton)%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0";
  window.location.href = whatsappLink;
};
const Laytone = () => {
  return (
    <div>
      <Hero desktopImage={headerlayton} mobileImage={laytonemobile} />
      <Producthero
        logoproduct={productdata.src}
        header={productdata.header}
        des={productdata.des}
      />
      <Promo data={promolayton} promo={laytonpromoimg} promoclick={walayton} />
      <Productlogo prodlogo={laytonlogo} />
      <Cardlayton />
      <Surrounding bg={bg} color={color} />
      <Map />
    </div>
  );
};

export default Laytone;
