import React from "react";
import "./Cardruko.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faBed,
  faHouse,
  faShower,
} from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Cardruko = ({
  title,
  subtitle,
  details,
  slider,
  style,
  whatsappbutton,
}) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  };

  return (
    <div className="cardruko" style={style}>
      <div className="cardcontent">
        <div className="contenttitle">{title}</div>
        <div className="contentsub">{subtitle}</div>
        <hr />
        <div className="contenticon">
          <span>
            <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
            &nbsp;: {details.luas}
          </span>
          <span>
            <FontAwesomeIcon icon={faHouse} />
            &nbsp;: {details.rumah}
          </span>
          <span>
            <FontAwesomeIcon icon={faBed} />
            &nbsp;: {details.kamar}
          </span>
          <span>
            <FontAwesomeIcon icon={faShower} />
            &nbsp;: {details.mandi}
          </span>
        </div>
        <div className="button">
          <button onClick={whatsappbutton} className="contentbutton">
            Whatsapp
          </button>
        </div>
      </div>
      <div className="cardgambar">
        <Slider {...settings}>
          {slider.map((image, index) => (
            <img
              className="gambar"
              key={index}
              src={image}
              alt={`Launching ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Cardruko;
