import React from "react";
import "./Map.scss";
import map from "../../media/Maps.png";

const Map = () => {
  return (
    <div className="mapcontainer">
      <div className="mapdes">
        <div className="title">Lokasi Navapark BSD City</div>
        <div className="subtitle">
          Lokasi yang ideal untuk tinggal dan berbisnis dengan banyak
          infrastruktur
        </div>
        <div className="buttonmap">
          <button
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send/?phone=6282122005507&text=Halo%20Dean,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Navapark%20https://bsdcity-navapark.id/&type=phone_number&app_absent=0",
                "_blank"
              )
            }
            className="button">
            Whatsapp
          </button>
        </div>
      </div>
      <div className="mappic">
        <img src={map} className="map" alt="Map BSD City" />
      </div>
    </div>
  );
};

export default Map;
